import React from 'react';
import { Link } from 'gatsby';

import Axios from 'axios';

const queryString = require('query-string');

class ThankYou extends React.Component {

    state = {
        loaded: false,
        quote: {}
    }

    async componentDidMount() {

        const queryparams = queryString.parse(this.props.location.search);
        const uid = queryparams.uid;
        const { data } = await Axios('/api/quote/' + uid);
        let vmail = await Axios.post('/api/serviceRequestVerificationFix/' + uid).then(data => {console.log(data)}); //in the background
        // const vmail = await Axios.post('/api/serviceRequestVerification', {data: data}); 
        console.log(vmail); 
        this.setState({ loaded: true, quote: data });
    }

    render() {
        
        const { t } = this.props;
        let status;

        switch (true) {
            case this.state.quote.payment_status == 'failed':
                status = 2;
                break;
            case this.state.quote.payment_status == 'canceled':
                status = 3;
                break;
            case this.state.quote.payment_status == 'banktransfer':
                status = 1;
                break;
            case this.state.quote.payment_status == 'paid':
            case this.state.quote.is_free == 'free':
            case this.state.quote.is_free == 'already paid':
            case parseFloat(this.state.quote.price) === 0: 
                status = 0;
                break;
            default:
                status = 1;
                break;
        }
        return (
            <>
                <div className="ty container">
                    <div className="row mt-4 mb-1">
                        <div className="col-12">
                            <h1>{t('Booking')} </h1>
                        </div>
                        <div className="col-12">
                            <h6>{t('Help us select the best service professional by answering a few more questions')}</h6>
                        </div>
                    </div>
                    <div className="row mt-1 mb-5 ty-breadcrumb">
                        <div className="col-12">
                            <div className="d-flex justify-content-start align-items-center">
                                <div className="mr-3">
                                    {this.state.quote.category}
                                </div>
                                /
                                <div className="mr-3 ml-3">
                                    {this.state.quote.brand}
                                </div>
                                /
                                <div className="mr-3 ml-3">
                                    {this.state.quote.model}
                                </div>
                                /
                                <div className="mr-3 ml-3">
                                    {this.state.quote.service}
                                </div>
                                /
                                <div className="mr-3 ml-3">
                                    {t('Booking')} #{this.state.quote.uid}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={status} style={{ display: `${status === 0 ? 'block' : 'none'}` }}>
                        <div className="col-12 bordered-body">
                            <h3>{t('Thank you!')}</h3>
                            <div className="ty-subtitle">
                                {t('your booking has been succesfully registered')}
                            </div>
                            <p>
                                {t('Our employee will process your request and call you soon on your shared telephone number to make an appointment. Together with our employee on the telephone, you will choose a date for a service visit. You will also receive a confirmation by email.')}
                            </p>
                            <span className="ty-bottom-txt">
                                {t('This service visit is carried out with the greatest care! Our qualified technicians and service staff ensure this!')}
                            </span>


                            <div className="ty-bottom-small-text">
                                {t('Still need information?')}<br />
                                <Link to="mailto:service@doitforme.services">{t('click here')}</Link> {t('and ask your question by mail or use the')} <Link to="https://doitforme.services/nl_be/be/contact/">{t('contact form')}</Link>
                            </div>
                            <Link to="">
                                <div className="ty-last-btn">
                                    {t('Book Another Service')}
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div style={{ display: `${status === 2 ? 'block' : 'none'}` }}>
                        <div className="col-12 bordered-body">
                            <h3>{t('Oops!')}</h3>
                            <div className="ty-subtitle red">
                                {t('your payment didn’t go through due to some error')}
                            </div>
                            <p>
                                {t('In case any amount has been deducted from your account, it will be credited back to the same payment mode with in 4-5 business days from the day of transaction.')}
                            </p>


                            <div className="ty-bottom-small-text">
                                {t('Still need information?')}<br />
                                <a href="mailto:service@doitforme.services">{t('click here')}</a> {t('and ask your question by mail or use the')} <a href="https://doitforme.services/nl_be/be/contact/">{t('contact form')}</a>
                            </div>
                            <Link to="">
                                <div className="ty-last-btn">
                                    {t('Try Again')}
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div style={{ display: `${status === 1 ? 'block' : 'none'}` }}>
                        <div className="col-12 bordered-body">
                            <h3>{t('Thank you!')}</h3>
                            <div className="ty-subtitle yellow">
                                {t('for your order! As soon as we receive payment confirmation, we will contact you.')}
                            </div>
                            <p>
                                {t('In case any amount has been deducted from your account, it will be credited back to the same payment mode with in 4-5 business days from the day of transaction.')}
                            </p>


                            <div className="ty-bottom-small-text">
                                {t('Still need information?')}<br />
                                <a href="mailto:service@doitforme.services">{t('click here')}</a> {t('and ask your question by mail or use the')} <a href="https://doitforme.services/nl_be/be/contact/">{t('contact form')}</a>
                            </div>
                            <Link to="">
                                <div className="ty-last-btn">
                                    {t('Try Again')}
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div style={{ display: `${status === 3 ? 'block' : 'none'}` }}>
                        <div className="col-12 bordered-body">
                            <h3>{t('Payment Cancelled!')}</h3>
                            <div className="ty-subtitle yellow">
                                {t('You have cancelled the transaction.')}
                            </div>
                            <p>
                                {t('In case any amount has been deducted from your account, it will be credited back to the same payment mode with in 4-5 business days from the day of transaction.')}
                            </p>


                            <div className="ty-bottom-small-text">
                                {t('Still need information?')}<br />
                                <a href="mailto:service@doitforme.services">{t('click here')}</a> {t('and ask your question by mail or use the')} <a href="https://doitforme.services/nl_be/be/contact/">{t('contact form')}</a>
                            </div>
                            <Link to="">
                                <div className="ty-last-btn">
                                    {t('Try Again')}
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </>
        )
    }
}

export default ThankYou;
